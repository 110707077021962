import { ScrollView, Stack, useBreakpointValue } from 'native-base';
import { useMemo, useRef, useState } from 'react';

import { BannerCarousel } from './BannerCarousel';
import { ProductSection } from './ProductSection';
import { ShopAnchorBar } from './ShopAnchorBar';

import { Image } from '@/components/Elements';
import { Footer } from '@/components/Portal';
import { ASSETS_URL } from '@/constants';
import { useIsMobile } from '@/hooks';

export const ShopTab = () => {
  const snacksRef = useRef<HTMLDivElement>(null);
  const supplementsRef = useRef<HTMLDivElement>(null);
  const merchRef = useRef<HTMLDivElement>(null);

  const [scrollOffset, setScrollOffset] = useState(0);
  const isMobile = useIsMobile();
  const bannersBaseUri = `${ASSETS_URL}/banners/shop-`;
  const iconsBaseUri = `${ASSETS_URL}/pictos/Icons/`;
  const mobileBannerUris = [...Array(3)].map((_, i) => `${bannersBaseUri}mobile-${i + 1}.png`);
  const sectionList = useMemo(
    () => [
      {
        title: 'Snacks',
        ref: snacksRef,
        bannerUri: `${bannersBaseUri}snacks-${isMobile ? 'mobile' : 'desktop'}.png`,
        iconUri: `${iconsBaseUri}shop-snack.png`,
      },
      {
        title: 'Supplements',
        ref: supplementsRef,
        bannerUri: `${bannersBaseUri}supplements-${isMobile ? 'mobile' : 'desktop'}.png`,
        iconUri: `${iconsBaseUri}shop-supplement.png`,
      },
      {
        title: 'Merch',
        ref: merchRef,
        bannerUri: `${bannersBaseUri}merch-${isMobile ? 'mobile' : 'desktop'}.png`,
        iconUri: `${iconsBaseUri}shop-merch.png`,
      },
    ],
    [bannersBaseUri, iconsBaseUri, isMobile]
  );

  const stickyHeaderIndex = useBreakpointValue({ base: 0, lg: undefined });

  const scrollViewDirection = useBreakpointValue({ base: 'column', lg: 'row' });

  return (
    <Stack w="100%" h="100%" alignItems="center">
      <ScrollView
        style={{ width: '100%' }}
        contentContainerStyle={{
          justifyContent: 'center',
          flexDirection: scrollViewDirection,
        }}
        onScroll={(e) => setScrollOffset(e.nativeEvent.contentOffset.y)}
        scrollEventThrottle={300}
        stickyHeaderIndices={[stickyHeaderIndex]}
      >
        <ShopAnchorBar sectionList={sectionList} scrollOffset={scrollOffset} />
        <Stack
          space={{ base: 6, lg: 9 }}
          w="100%"
          maxW={{ lg: '1024px' }}
          mt={{ base: 6, lg: 0 }}
          mb={{ base: '524px', lg: '478px' }}
          pt={{ base: 0, lg: 12 }}
        >
          {isMobile ? (
            <BannerCarousel uris={mobileBannerUris} />
          ) : (
            <Stack
              borderRadius="8px"
              overflow="hidden"
              w="100%"
              maxW={{ lg: '1024px' }}
              h={{ base: '140px', lg: '320px' }}
              mt={{ base: 6, lg: 0 }}
            >
              <Image
                w="100%"
                h="100%"
                resizeMode="cover"
                source={{ uri: `${ASSETS_URL}/banners/shop-desktop.png` }}
              />
            </Stack>
          )}
          <Stack space={{ base: 9, lg: 12 }} px={{ base: 4, lg: 0 }}>
            {sectionList.map(({ title, ref, bannerUri, iconUri }) => {
              return (
                <ProductSection title={title} ref={ref} bannerUri={bannerUri} iconUri={iconUri} />
              );
            })}
          </Stack>
        </Stack>
        <Footer position="absolute" bottom={0} left={0} />
      </ScrollView>
    </Stack>
  );
};
