import { Stack } from 'native-base';
import { useState } from 'react';
import { GestureResponderEvent } from 'react-native';

import { ProductBodyData } from '@/api';
import { PetPlanProductStatus, RecipeFull } from '@/api/types';
import {
  PetPillButton,
  PopUpProps,
  PortalPopUp,
  ToastType,
  displayToast,
} from '@/components/Elements';
import { useAccount, useAuth } from '@/hooks';

type SelectDogProps = {
  recipe: RecipeFull;
  onConfirm: ({
    petPlanId,
    products,
  }: {
    petPlanId: string;
    products: ProductBodyData[];
  }) => Promise<any>;
  onSuccess?: () => void;
  isLoading: boolean;
  /**
   * Filters shown pets by these IDs. If undefined, show all active pets.
   */
  petIds?: string[];
} & Omit<PopUpProps, 'onConfirm'>;

export const DogSelectModal = ({
  recipe,
  onConfirm,
  onSuccess,
  isLoading,
  petIds,
  ...props
}: SelectDogProps) => {
  const account = useAccount();
  let activePets = account.pets
    .filter((pet) => pet.status === 'ACTIVE')
    .sort((petA, petB) => petA.name.localeCompare(petB.name));
  const { refetchUser } = useAuth();
  if (petIds) {
    activePets = activePets.filter((pet) => petIds.includes(pet.id));
  }
  activePets = activePets.filter((pet) =>
    pet.pet_plan.products
      .filter((product) => product.status !== PetPlanProductStatus.CANCELLED)
      .every((product) => product.code !== recipe.default_product.code)
  );

  const [selectedPets, setSelectedPets] = useState<
    { id: string; name: string; petPlanId: string }[]
  >([]);

  const togglePet = (e: GestureResponderEvent, petId: string) => {
    e.preventDefault();
    const pet = activePets.find((pet) => pet.id === petId);
    if (selectedPets.map((pet) => pet.id).includes(petId)) {
      setSelectedPets(selectedPets.filter((pet) => petId !== pet.id));
    } else if (pet) {
      setSelectedPets([...selectedPets, { id: petId, name: pet.name, petPlanId: pet.pet_plan.id }]);
    }
  };
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const handleConfirm = async () => {
    const snack = recipe.default_product;
    const successes = [];

    setIsRequestLoading(true);
    try {
      for (const pet of selectedPets) {
        await onConfirm({
          petPlanId: pet.petPlanId,
          products: [{ code: snack.code, quantity: 1, recurring: true }],
        });
        successes.push(pet.name);
      }
    } catch (err) {
      displayToast({
        message: `An error occurred while adding ${snack.name} to one or more of your plans.`,
        type: ToastType.Error,
      });
      console.error(err);
    }

    await refetchUser();
    onSuccess?.();
    setIsRequestLoading(false);

    for (const petName of successes) {
      displayToast({
        message: `${snack?.name} have been added to ${petName}'s plan.`,
        type: ToastType.Success,
      });
    }
  };

  return (
    <PortalPopUp
      heading={`Which plans would you like to add ${recipe.name} to?`}
      size="xl"
      isConfirmButtonDisabled={selectedPets.length === 0 || isLoading || isRequestLoading}
      onConfirm={handleConfirm}
      childSpacerSize={0}
      useRNModal
      {...props}
    >
      <Stack px="20px" w="100%" space={{ base: '16px', lg: '24px' }}>
        <Stack flexWrap="wrap" direction="row" space={{ base: '16px', lg: '24px' }} py={4}>
          {activePets.map((pet) => (
            <PetPillButton
              pet={pet}
              selected={selectedPets.map((pet) => pet.id).includes(pet.id)}
              key={pet.id}
              onPress={(e) => togglePet(e, pet.id)}
              marginTop={{ base: '8px', lg: '12px' }}
              marginBottom={{ base: '8px', lg: '12px' }}
              isDisabled={isRequestLoading}
            />
          ))}
        </Stack>
      </Stack>
    </PortalPopUp>
  );
};
