import { NavigationProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import { Pressable } from 'native-base';
import { useCallback, useState } from 'react';

import { BannerWrapper } from './BannerWrapper';
import { DesktopProductImages } from './DesktopProductImages';
import { MobileProductImages } from './MobileProductImages';

import { useAccount, useIsMobile } from '@/hooks';
import segment from '@/segment';
import { ProtectedStackParamList } from '@/types';

export const ShopBanner = () => {
  const navigation = useNavigation<NavigationProp<ProtectedStackParamList>>();
  const account = useAccount();

  const isMobile = useIsMobile();
  const [bannerImage, setBannerImage] = useState<string | undefined>();

  useFocusEffect(
    useCallback(() => {
      if (!isMobile) {
        setBannerImage('desktop_banner');
      }
    }, [isMobile])
  );

  const navToShop = () => {
    if (account) {
      const currentUrl = window.location.href;
      segment.trackEvent('Clicked Banner', {
        account_id: account.id,
        email: account.email,
        page_path: currentUrl,
        banner_image: bannerImage,
      });
    }

    navigation.navigate('Shop');
  };

  return (
    <Pressable onPress={navToShop} w="100%" h={{ base: '123px', lg: '185px', xl: '221px' }}>
      <BannerWrapper onButtonPress={navToShop}>
        {isMobile ? (
          <MobileProductImages setBannerImage={setBannerImage} />
        ) : (
          <DesktopProductImages />
        )}
      </BannerWrapper>
    </Pressable>
  );
};
